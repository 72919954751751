<template>
    <div>
        <van-nav-bar :title="projectDetail.projectName ? projectDetail.projectName : '-'" left-text="返回" @click-left="back" fixed left-arrow :right-text="(active == 0 && (projectManagement || user.id==projectDetail.inchargerId || user.id==projectDetail.creatorId)) ? '编辑项目' : ''" @click-right="openProject()" />
        <!-- <van-nav-bar :title="projectDetail.projectName ? projectDetail.projectName : '-'" left-text="返回" @click-left="back" fixed left-arrow :right-text="activeTextFlg ? '编辑项目' : ''" @click-right="openProject()" /> -->
        <div class="content">
            <!-- <van-cell><div style="width:100%;text-align:center;font-size:120%">{{projectDetail.projectName ? projectDetail.projectName : '-'}}</div></van-cell> -->
            <van-tabs v-model="active" @click="activeChange">
                <!-- 项目概览 -->
                <van-tab title="项目概览">
                    <!-- 基本信息 -->
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目名称：</van-col>
                            <van-col span="17">{{projectDetail.projectName}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目分类：</van-col>
                            <van-col span="17">{{projectDetail.categoryName}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">{{user.companyId == '7030' ? '项目令号:' : '项目编号:'}}</van-col>
                            <van-col span="17">{{projectDetail.projectCode}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目描述：</van-col>
                            <van-col span="17">{{projectDetail.projectDesc}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell v-if="user.timeType.projectWithDept == 1">
                        <van-row>
                            <van-col span="7">所属部门：</van-col>
                            <van-col span="17">
                                <span v-if="user.userNameNeedTranslate != 1">{{projectDetail.departmentName}}</span>
                                <span v-else><TranslationOpenDataText type='departmentName' :openid='projectDetail.departmentName'></TranslationOpenDataText></span>
                            </van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">状态：</van-col>
                            <van-col span="17">{{projectDetail.status == null ? '-' : info.statusText[projectDetail.status]}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell v-if="user.company.packageEngineering == 0">
                        <van-row>
                            <van-col span="7">完成度：</van-col>
                            <van-col span="17">{{projectDetail.progress}}%</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">合同金额：</van-col>
                            <van-col span="17">￥{{projectDetail.contractAmount == null ? '-' : projectDetail.contractAmount.toFixed(2)}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目级别：</van-col>
                            <van-col span="17">{{levelToText(projectDetail.level)}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">创建日期：</van-col>
                            <van-col span="17">{{projectDetail.createDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">计划开始日期：</van-col>
                            <van-col span="17">{{projectDetail.planStartDate == null ? '-' : projectDetail.planStartDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">计划结束日期：</van-col>
                            <van-col span="17">{{projectDetail.planEndDate == null ? '-' : projectDetail.planEndDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">实际完成日期：</van-col>
                            <van-col span="17">{{projectDetail.finishDate == null ? '-' : projectDetail.finishDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell v-if="user.timeType.outputValueStatus == 1">
                        <van-row>
                            <van-col span="7">项目产值：</van-col>
                            <van-col span="17">￥{{projectDetail.outputValue == null ? '-' : projectDetail.outputValue.toFixed(2)}}</van-col>
                        </van-row>
                    </van-cell>

                    <!-- 威派格 -->
                <template v-if="user.companyId == 936">
                    <van-cell>
                        <van-row>
                            <van-col span="7">合同编号：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.contractCode ? projectDetail.projectSeparate.contractCode : '-'}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">质保开始时间：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.warrantyStartDate == null ? '-' : projectDetail.projectSeparate.warrantyStartDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">质保截至时间：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.warrantyEndDate == null ? '-' : projectDetail.projectSeparate.warrantyEndDate}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">自主项目类别：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.projectCategorySub}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">所属大区：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.region}}</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell>
                        <van-row>
                            <van-col span="7">所属BU：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.bu}}</van-col>
                        </van-row>
                    </van-cell>
                </template>

                    <!-- 长沙晶易科技 -->
                <!-- <template v-if="user.companyId == 428">
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目分组：</van-col>
                            <van-col span="17" v-if="projectDetail.projectSeparate">{{projectDetail.projectSeparate.projectCategorySub ? projectDetail.projectSeparate.projectCategorySub : '-'}}</van-col>
                        </van-row>
                    </van-cell>
                </template> -->

                    <!-- 相关人员 -->
                    <van-cell>
                        <van-row>
                            <van-col span="7">项目经理：</van-col>
                            <van-col span="17">
                                <span v-if="user.userNameNeedTranslate != 1">{{projectDetail.inchargerName}}</span>
                                <span v-else><TranslationOpenDataText type='userName' :openid='projectDetail.inchargerName'></TranslationOpenDataText></span>
                            </van-col>
                        </van-row>
                    </van-cell>
                    <van-cell v-if="user.timeType.reportAuditType == 0 || user.timeType.reportAuditType == 4">
                        <van-row>
                            <van-col span="7">日报审核人：</van-col>
                            <van-col span="17" v-if="projectDetail.auditorList && projectDetail.auditorList.length != 0">
                                <span v-for="item,index in projectDetail.auditorList" :key="item.id">
                                    <span v-if="index != 0">，</span>
                                    <span v-if="user.userNameNeedTranslate != 1">{{item.auditorName}}</span>
                                    <span v-else><TranslationOpenDataText type='userName' :openid='item.auditorName'></TranslationOpenDataText></span>
                                </span>
                            </van-col>
                            <van-col span="17" v-else>-</van-col>
                        </van-row>
                    </van-cell>
                    <van-cell v-if="projectDetail.isPublic == 0">
                        <van-row>
                            <van-col span="7">参与人：</van-col>
                            <van-col span="17">
                                <span v-for="item,index in projectDetail.participationList" :key="item.id">
                                    <span v-if="index != 0">，</span>
                                    <span v-if="user.userNameNeedTranslate != 1">{{item.name}}</span>
                                    <span v-else><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                                </span>
                            </van-col>
                        </van-row>
                    </van-cell>
                </van-tab>
                <!-- 任务看板 -->
                <van-tab title="任务看板" v-if="activeFlg">
                    <van-cell title="任务分组" :value="inside.taskGroup.active?inside.taskGroup.active.name:''" @click="inside.taskGroup.show = true"></van-cell>
                    <van-popup v-model="inside.taskGroup.show" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="inside.taskGroup.list"
                        @confirm="taskGroupChange"
                        @cancel="inside.taskGroup.show = false;$forceUpdate();">
                            <template #option="item">
                                <span>{{item.name}}</span>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-cell title="任务列表" :value="inside.stages.active?inside.stages.active.stagesName:''" @click="inside.stages.show = true"></van-cell>
                    <van-popup v-model="inside.stages.show" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="inside.stages.list"
                        @confirm="stagesChange"
                        @cancel="inside.stages.show = false;$forceUpdate();">
                            <template #option="item">
                                <span>{{item.stagesName}}</span>
                            </template>
                        </van-picker>
                    </van-popup>
                    <!-- <van-cell value-class="addtaskvalue">
                        <van-button plain type="info" class="addtaskbutton" @click="toEditask(null)">新建任务</van-button>
                    </van-cell> -->
                    <van-icon name="add-o" class="addtaskicon" @click="toEditask(null)" />
                    <div class="taskList">
                        <van-cell v-for="item in inside.taskList" :key="item.id">
                            <div style="line-height:0.8rem">任务名称：{{item.name}}</div>
                            <div style="line-height:0.8rem">起止时间：
                                <span v-if="item.startDate && item.endDate">{{(item.startDate ? item.startDate : ' - ') + '\u3000至\u3000' + (item.endDate ? item.endDate : ' - ')}}</span>
                                <span v-else></span>
                            </div>
                            <div class="task_button">
                                <van-button size="small" type="danger" v-if="user.id == item.creatorId || user.id == projectDetail.creatorId ||user.id == projectDetail.inchargerId || projectManagement" @click="taskDelete(item)">删除</van-button>
                                <van-button size="small" type="info" @click="toEditask(item.id)">编辑</van-button>
                                <van-button size="small" type="primary" @click="taskStatus(item.id,0,item)" v-if="item.taskStatus == 0">完成</van-button>
                                <van-button size="small" color="#e6a23c" @click="taskStatus(item.id,1,item)" v-if="item.taskStatus == 1">重启</van-button>
                            </div>
                        </van-cell>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: JSON.parse(localStorage.userInfo),
            projectManagement: false,
            projectId: JSON.parse(sessionStorage.projectId),
            projectDetail: {},
            
            active: 0,
            activeFlg: true,
            activeTextFlg: false,

            inside:{    // 任务看板
                taskGroup:{     // 任务分组
                    show: false,
                    active: null,
                    list: []
                },
                stages:{        // 任务列表
                    show: false,
                    active: null,
                    list: []
                },
                taskList: []
            },
            info:{      // 项目概览
                statusText: ['全部','进行中','已完成','撤销'],
                levelList: [],
                levelText: ['全部','正常','紧急','重要','重要且紧急'],
            }
        }
    },
    created() {
        
    },
    mounted() {
        // console.log('project',this.project);
        for(let i in this.user.functionList){
            if(this.user.functionList[i].name == '管理全部项目'){
                this.projectManagement = true
            }
        }
        if(this.user.company.packageWorktime == 1) {
            this.activeFlg = false
        }

        this.getProjectDetail()
        this.getLevelList()
        this.getTaskGroupList()

    },
    methods: {
        back() {
            history.back();
        },
        activeChange(name,title){
            // console.log('activeChange',name,title);
        },
        // 任务看板
        taskGroupChange(value,key){  // 任务分组
            // console.log('taskGroupChange',value,key);
            this.inside.taskGroup.active = value
            this.inside.taskGroup.show = false

            this.getStagesList()
        },
        stagesChange(value,key){     // 任务列表
            // console.log('stagesChange',value,key);
            this.inside.stages.active = value
            this.inside.stages.show = false

            this.inside.taskList = value.taskList
        },

        // 新建/编辑任务
        toEditask(taskid){
            let item
            if(taskid == null){
                item = {
                    addNew: true,
                    id: null,
                    groupId: this.inside.taskGroup.active.id,
                    stagesId: this.inside.stages.active.id,
                    showOrNot: false
                }
            }else{
                item = {
                    addNew: false,
                    id: taskid,
                    groupId: null,
                    stagesId: null,
                    showOrNot: false
                }
            }
            sessionStorage.setItem('taskId',JSON.stringify(item))
            this.$router.push("/editask");
        },
        taskStatus(taskid,status,taskitem){          // 改变任务状态
            this.$axios.post("/task/finish", {
                id: taskid,
                taskStatus: status
            }).then(res => {
                if(res.code == "ok") {
                    this.$toast.success('操作成功');
                    taskitem.taskStatus = status ? 0 : 1
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        taskDelete(taskitem){
            this.$dialog.confirm({
                title: '提示',
                message: taskitem.subTaskList.length > 0 ? '您确定删除当前任务及其子任务吗？' : '您确定删除当前任务吗？'
            }).then(()=>{
                this.$axios.post("/task/delete", {id: taskitem.id})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('删除成功');
                        this.inside.taskList = this.inside.taskList.filter(item => item.id != taskitem.id)
                    } else {
                        this.$toast.fail('操作失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            }).catch(()=>{})
        },
        
        openProject(){
            if(this.active == 0 && (this.projectManagement || this.user.id==this.projectDetail.inchargerId || this.user.id==this.projectDetail.creatorId)){
                this.$router.push("/editProject");
            }
        },
        
        


        getTaskGroupList(){     // 任务分组列表
            this.$axios.post("/task-group/list", {
                projectId:this.projectId
            }).then(res => {
                if(res.code == "ok") {
                    this.inside.taskGroup.list = res.data
                    this.inside.taskGroup.active = res.data[0]
                    this.getStagesList()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getStagesList(){        // 任务列表
            this.$axios.post("/stages/list", {
                groupId: this.inside.taskGroup.active.id,
                projectId: this.projectId,
                order: 'seq',
                isDesc: false
            }).then(res => {
                if(res.code == "ok") {
                    this.inside.stages.list = res.data.list
                    this.inside.stages.active = res.data.list[0]
                    this.inside.taskList = res.data.list[0].taskList
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },

        // 项目概览
        getProjectDetail(){
            this.$axios.post("/project/detail", {id: this.projectId})
            .then(res => {
                if(res.code == "ok") {
                    this.projectDetail = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        levelToText(lev){
            console.log('levelToText',lev,this.info.levelList,this.info.levelList.filter(item => item.id == lev));
            if(this.user.timeType.projectLevelState == 1){
                if(this.info.levelList.filter(item => item.id == lev).length == 0){
                    return this.info.levelText[lev]
                }
                return this.info.levelList.filter(item => item.id == lev)[0].projectLevelName
            }else{
                return this.info.levelText[lev]
            }
        },
        getLevelList(){     // 自定义项目级别
            this.$axios.post("/project-level/list", {})
            .then(res => {
                if(res.code == "ok") {
                    this.info.levelList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        }
    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    overflow: auto;
    .addtaskvalue{
        text-align: center;
        .addtaskbutton{
            width: 70%;
            height: 30px;
        }
    }
    .addtaskicon{
        opacity: 0.5;
        position: fixed;
        z-index: 1000;
        font-size: 50px;
        color: #1989fa;
        background-color: #fff;
        border-radius: 50%;
        bottom: 33px;
        right: 33px;
    }
    .task_button{
        padding-top: .09rem;
        text-align: right;
        button{
            margin-left: .2667rem;
        }
    }
}
</style>